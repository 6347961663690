<template>
    <v-chart class="chart" :option="option" />
</template>
  
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, BarChart } from "echarts/charts";
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    ToolboxComponent,
    GridComponent,
    MarkLineComponent,
    MarkPointComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { max } from "zrender/lib/core/vector";

use([
    CanvasRenderer,
    LineChart,
    BarChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    ToolboxComponent,
    GridComponent,
    MarkLineComponent,
    MarkPointComponent
]);

export default {
    props: ['timeline', 'tempData', 'settings'],
    components: {
        VChart
    },
    provide: {
        // [THEME_KEY]: "dark"
    },
    data() {
        return {
            option: {
                title: {
                    text: 'Device Temperature',
                        left: 'center',
                        top: 20,
                        textStyle: {
                            // color: '#333',
                            fontSize: 14
                        }
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {},
                toolbox: {
                    show: true,
                    feature: {
                        magicType: { type: ['line', 'bar'] },
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: this.timeline,
                    axisLabel: {
                        color: "white"
                    }
                },
                yAxis: {
                    type: 'value',
                    // max: Math.round((Math.round(this.settings.maxTemp) + 25) / 5) * 5,
                    // min: Math.round((Math.round(this.settings.minTemp) - 10) / 5) * 5,
                    axisLabel: {
                        formatter: '{value} °C',
                        color: "white",

                    }
                },
                series: [
                    {
                        type: 'line',
                        color: 'lightGreen',
                        data: this.tempData,
                        markPoint: { data: [{ type: 'max', name: 'Max' }, { type: 'min', name: 'Min' }] },
                        markLine: {
                            symbol: 'none',
                            data: [{
                                yAxis: this.settings.maxTemp,
                                label: {
                                    position: 'end',
                                    formatter: `Max ${this.settings.maxTemp} °C`
                                },
                                lineStyle: {
                                    type: 'dashed',
                                    color: 'red',

                                },
                            },
                            {
                                yAxis: this.settings.minTemp,
                                label: {
                                    position: 'end',
                                    formatter: `Min ${this.settings.minTemp} °C`
                                },
                                lineStyle: {
                                    type: 'dashed',
                                    color: 'red',

                                },
                            },
                            {
                                yAxis: this.settings.desiredTemp,
                                label: {
                                    position: 'end',
                                    formatter: `Set ${this.settings.desiredTemp} °C`
                                },
                                lineStyle: {
                                    type: 'dashed',
                                    color: 'lightBlue',

                                },
                            }]
                        }
                    },                   
                ]
            }
        };
    }
};
</script>
  
<style scoped>
.chart {
    height: 65vh;
}
</style>