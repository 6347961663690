<template>
    <div>
        <v-card>
            <div v-if="loading">
                <v-card-text>
                    <v-row align="center" justify="center">
                        <v-col cols="12" sm="12" md="12">
                            <v-progress-circular :size="35" :width="7" color="primary"
                                indeterminate></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-card-text>
            </div>
            <div v-else>
                <div >
                    <SensiTempChart :key="key" :timeline="tempTimeline" :tempData="tempReadings" :settings="tempSettings" />
                </div>
                <!-- <div v-else>
                    <span style="color: grey">No device found.</span>
                </div> -->

            </div>
        </v-card>

    </div>
</template>

<script>
import SensiTempChart from "../Charts/SensiTempChart.vue";
export default {
    props: ["container", "monitoringData"],
    components: {
        SensiTempChart,
    },
    data: () => ({
        loading: false,
        tempSettings: {},
        tempReadings: [],
        tempTimeline: [],
        device: {},
        key: 1000,
    }),
    watch: {
        'monitoringData.temperatureData': {
            immediate: true,
            handler(){
                this.getContainerDevice();
            }
        }
    },
    mounted() {
        // this.getContainerDevice();
    },
    methods: {
        async getContainerDevice() {
            this.loading = true
            console.log(this.container);
            if (this.container && this.container.sensorId) {
                // this.device = await this.$API.getSensorDeviceById(this.container.sensorId)
                await this.getRegimeData()
                await this.loadTempReadings()
            }
            this.loading = false
        },
        async loadTempReadings() {
            this.loading = true
            // let res = await this.$API.getDeviceTempReadings(this.device.id);
            if (this.monitoringData.temperatureData && this.monitoringData.temperatureData.length > 0) {
                this.tempReadings = this.monitoringData.temperatureData.map(x => { return x.temperature.toFixed(2) })
                this.tempTimeline = this.monitoringData.temperatureData.map(x => { return x.readingDate.replace('T', ' ').replace('Z', '') })
            }
            // console.log(this.tempReadings)
            // console.log(this.tempTimeline)
        },
        async getRegimeData() {
            let resp = await this.$API.getRigimeByID(this.container.booking.regimeCode)
            this.tempSettings.maxTemp = resp.maxProductTemp
            this.tempSettings.desiredTemp = resp.setPointTemp
            this.tempSettings.minTemp = resp.minProductTemp
            // console.log(this.tempSettings)
            // this.key ++
            this.loading = false
        },
    }
}
</script>

<style>

</style>